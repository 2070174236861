'use client'

import { ChangeEvent, useRef, useState, DragEvent, MouseEvent } from 'react'
import { Button } from './button'
import { Upload, FileText, X } from 'lucide-react'

interface FileUploadProps {
  label: string
  fileType: 'cv' | 'job'
  onFileSelect: (file: File | null) => void
  selectedFile: File | null
  acceptedTypes?: string[]
}

export default function FileUpload({ 
  label, 
  fileType, 
  onFileSelect, 
  selectedFile, 
  acceptedTypes = ['.pdf', '.doc', '.docx'] 
}: FileUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isDragging, setIsDragging] = useState(false)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      validateAndSelectFile(file)
    }
  }

  const validateAndSelectFile = (file: File) => {
    const validTypes = acceptedTypes || ['.pdf', '.doc', '.docx', '.txt']
    const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`
    
    if (!validTypes.includes(fileExtension)) {
      alert('Please upload a valid file type')
      return
    }
    
    onFileSelect(file)
  }

  const handleRemoveFile = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    onFileSelect(null)
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    
    const file = e.dataTransfer.files[0]
    if (file) {
      validateAndSelectFile(file)
    }
  }

  return (
    <div
      className={`
        relative w-full min-h-[200px] 
        border-2 border-dashed rounded-xl
        ${isDragging 
          ? 'border-blue-500 bg-blue-50' 
          : selectedFile 
            ? 'border-green-500 bg-green-50' 
            : 'border-gray-300 hover:border-gray-400'
        }
        transition-all duration-200
      `}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={acceptedTypes?.join(',') || '.pdf,.doc,.docx,.txt'}
        className="hidden"
        aria-label={`Upload ${fileType.toUpperCase()}`}
      />

      {selectedFile ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
          <button
            onClick={handleRemoveFile}
            className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 text-gray-500 hover:text-gray-700 transition-colors z-10"
            title="Remove file"
            type="button"
          >
            <X className="w-4 h-4" />
          </button>
          <FileText className="w-12 h-12 text-green-500 mb-2" />
          <p className="text-sm font-medium text-gray-900 text-center break-all">
            {selectedFile.name}
          </p>
          <p className="text-xs text-gray-500 mt-1">
            {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
          </p>
        </div>
      ) : (
        <Button
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputRef.current?.click()
          }}
          variant="ghost"
          className="w-full h-full min-h-[200px] rounded-xl hover:bg-gray-50/50 flex flex-col items-center justify-center gap-2"
        >
          <Upload className="w-8 h-8 text-gray-500" />
          <div className="text-center">
            <p className="font-medium text-gray-900">{label}</p>
            <p className="text-sm text-gray-500 mt-1">
              Drag & drop or click to upload
            </p>
          </div>
        </Button>
      )}
    </div>
  )
}